<template>
<div class="introduction">
  <p>Hi! My name is Susanna. Here you can find my CV and BCS web application development training certificate. </p>
</div>
</template>

<script>
export default {
name: "Introduction"
}
</script>

<style scoped>
.introduction {
  z-index: 90;
  position: absolute;
  color: #FFF;
  top: 53%;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 2em;
}

</style>